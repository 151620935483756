/* eslint-disable no-nested-ternary */

'use client';

import clsx from 'clsx';

import Image from 'next/image';

import { useBreakpoint } from '@/lib/style/hooks';

import { Card } from './ui/card';

interface IconTileProps extends React.PropsWithChildren {
  logo: string;
  color: string;
  projectName: string;
  className?: string;
}

export default function IconTile({
  logo,
  color,
  projectName,
  className,
}: IconTileProps) {
  const isDesktop = useBreakpoint('xl');
  const isTablet = useBreakpoint('md');
  return (
    <Card
      className={clsx('rounded-2xl rounded-tr-md lg:rounded-4xl', className)}
      style={{ backgroundColor: color }}
    >
      <div className="relative flex size-16 items-center justify-center md:size-24 xl:size-32">
        <Image
          priority
          src={logo}
          alt={projectName}
          className="size-16 rounded-2xl rounded-tr-md object-cover md:size-24 xl:size-32"
          width={isDesktop ? 128 : isTablet ? 96 : 64}
          height={isDesktop ? 128 : isTablet ? 96 : 64}
        />
      </div>
    </Card>
  );
}
